<template>
  <div class="home_wrapper">
    <div class="container">
      <!-- <ul class="tab_list">
        <li v-for="(item, index) in tabList" :key="index" :class="{ tab_box: true, tab_box_selected:  index === active}" @click="selectMenu(index)">{{item}}</li>
      </ul> -->
      <div class="list" v-if="productData.length">
        <div v-for="(item, index) in productData" :key="index">
          <pro-item :item="item"></pro-item>
        </div>
      </div>
      <div v-else>
        <van-empty description="暂无数据" />
      </div>
    </div>
  </div>
</template>

<script>
import './ProductList.less'
import { Empty } from 'vant'
import 'swiper/css/swiper.css'
import ProductItem from './../../components/ProductItem'
import { getHomeData } from '@/api/api'
import { baseConfig } from '../../utils/request'


export default {
  name: 'ProductList',
  data() {
    return {
      tabList: [
        '行业保障',
        '阿姨保险',
        '雇主家庭'
      ],
      active: 0,
      // 商品数据
      productData: []
    }
  },
  components: {
    'van-empty': Empty,
    'pro-item': ProductItem,
  },
  async mounted() {
    // localStorage.setItem('loginInfo', JSON.stringify({"openId":"o2mvUt_RGn_wWGIhwJUzupSmnlPM","unitId":"123"}))
    let loginInfo = localStorage.getItem('loginInfo');
    if (!loginInfo || !JSON.parse(loginInfo).openId || !JSON.parse(loginInfo).unitId) {
      const url = `${baseConfig.locationHref}`;
      const oldPath = encodeURIComponent(window.location.href.split(location.origin)[1] + '&login');
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${baseConfig.appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=${oldPath}`
    }
    else {
      const data = await getHomeData({});
      this.handleData(data);
    }
  },

  methods: {
    selectMenu(index) {
      this.active = index;
    },
    handleData(data) {
      console.log(data);
      this.productData = data.data;
      this.productData.forEach(item => {
        item.insurances = JSON.parse(item.insurances);
      });
      console.log(this.productData);
    }
  },
}
</script>
