import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import router from './module/router'
export default new Vuex.Store({
  namespaced: true,

  state: {
    webTitle: '',
    bookNursingFormPolicyNo:'',
    bookNursingFormChannelCode:'',
  },
  mutations: {
    setWebTitle(state, value) {
      state.webTitle = value;
    },
    setBookNursingFormPolicyNo(state, value) {
      state.bookNursingFormPolicyNo = value;
    },
    setBookNursingFormChannelCode(state, value) {
      state.bookNursingFormChannelCode = value;
    }
  },
  actions: {
    setWebTitle(context, value) {
      context.commit('setWebTitle', value)
    },
    setBookNursingFormPolicyNo(context, value) {
      context.commit('setBookNursingFormPolicyNo', value)
    },
    setBookNursingFormChannelCode(context, value) {
      context.commit('setBookNursingFormChannelCode', value)
    },
  },
  modules: {
    router
  }
})
