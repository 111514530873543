export default {
    state: {
        routesHistory: []
    },
    mutations: {
        goUrl(state, targetUrl) {
            //取地址
            const path = targetUrl.slice(0, targetUrl.indexOf('?') >= 0 ? targetUrl.indexOf('?') : targetUrl.length);
            //取参数
            const query = targetUrl.slice(targetUrl.indexOf('?') >= 0 ? targetUrl.indexOf('?') + 1 : targetUrl.length);
            //去除参数后判断是否存在该路由
            const existUrl = state.routesHistory.filter(res => {
                return res.slice(0, targetUrl.indexOf('?') >= 0 ? targetUrl.indexOf('?') : targetUrl.length) === path;
            });
            const routesIndex = state.routesHistory.indexOf(existUrl[0]);
            if (existUrl.length > 0) state.routesHistory.splice(routesIndex + 1);
            //需要把参数传回去，不然会导致reback时没了参数
            else state.routesHistory.push(query ? `${path}?${query}` : path);
        }
    },
    getters: {},
    actions: {
        goUrl(context, value) {
            context.commit('goUrl', value)
        }
    }
}
