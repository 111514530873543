import {baseConfig, request} from '../utils/request'
// 获取首页数据
const getHomeData = (data = {}) => {
    return request({
        url: `${baseConfig.skURL}/ewp/productList`,
        method: 'GET',
        params: data
    })
}

// 获取商品详情
const getProductDetail = (data = {}) => {
    return request({
        url: `${baseConfig.skURL}/ewp/productDetails`,
        method: 'GET',
        params: data
    })
}
// 获取商品详情
const getProductInsuredAttr = (data = {}) => {
    return request({
        url: '/product/productInsuredAttr',
        method: 'POST',
        data
    })
}
//产品试算
const getPremium = (data = {}) => {
    return request({
        url: `${baseConfig.skURL}/ewp/getPremium`,
        method: 'POST',
        data,

    })
}
// 获取电子保单
const getElectPolicy = (query) => {
    return request({
        url: `/insurance/getElectPolicyUrl`,
        method: 'GET',
        params: query
    })
}
// 获取常用投保人列表
const getPolicyHolderManagement = (data = {}) => {
    return request({
        url: '/commonHolder/queryArray',
        method: 'POST',
        data
    })
}

// 获取保单列表
const getMyOrders = (data = {}) => {
    return request({
        url: `${baseConfig.skURL}/policy/H5/employee/policies`,
        method: 'GET',
        params: data
    })
}


// http://ttapi.dasurebao.com/wechat/insurance/api/policyDetail
// 获取保单详情数据
const getPolicyDetailData = (data = {}) => {
    return request({
        url: `${baseConfig.skURL}/policy/H5/employee/getOne`,
        method: 'GET',
        params: data
    })
}

// 撤保
const undoPolicy = (data = {}) => {
    return request({
        url: `${baseConfig.skURL}/policy/H5/employee/withdrawal`,
        method: 'POST',
        data,
        changeAppkey: true,
        appKey: ''
    })
}

// 获取声明及须知
const getDeclaration = (data = {}) => {
    return request({
        url: '/product/getProductDetail',
        method: 'POST',
        data
    })
}
// 生成保单（身份验证）
const savePolicy = (data = {}) => {
    return request({
        url: '/insurance/newSaveAuthPolicy',
        method: 'POST',
        data,
    })
}

// 生成保单
const saveOrderPolicy = (data = {}) => {
    return request({
        url: `${baseConfig.skURL}/policy/save`,
        method: 'POST',
        data,
    })
}

// 首页关键字搜索
const searchKeyword = (data = {}) => {
    return request({
        url: '/product/serachProduct',
        method: 'POST',
        data
    })
}

// 检查身份验证状态
const checkIfVerify = (data = {}) => {
    return request({
        url: `${baseConfig.skURL}/policy/H5/employee/checkIfVerify`,
        method: 'GET',
        params: data
    })
}

// 检查身份验证状态
const getPayInfo = (data = {}) => {
    return request({
        url: `${baseConfig.skURL}/policy/H5/employee/startPay`,
        method: 'POST',
        data
    })
}

// 获取声明
const getTermsDetail = (data = {}) => {
    return request({
        url: '/product/getProductDetail',
        method: 'POST',
        data
    })
}
// ocr图片
const upLoadOcr = (data = {}) => {
    return request({
        url: '/insurance/newOcr',
        method: 'POST',
        data,
    })
}
// 支付参数
const getPayParams = (data = {}) => {
    return request({
        url: '/insurance/api/payH5',
        method: 'POST',
        data,

    })
}
// 登录
const getLogin = (data = {}) => {
    return request({
        url: `${baseConfig.skURL}/mp/authCode`,
        method: 'GET',
        params: data,
    })
}
// 获取用户信息
const getUserInfor = (data = {}) => {
    return request({
        url: '/gzhController/getUserInfor',
        method: 'POST',
        data,
        changeAppkey: true,
        appKey: 'dsbjkgzh'
    })
}

// 预约接口
const userBooking = (data = {}) => {
    return request({
        url: baseConfig.sunlightUrl,
        method: 'POST',
        data
    })
}

// 获取地区
const getAreaList = (data = {}) => {
    return request({
        url:  baseConfig.arealist,
        method: 'GET',
        params: data
    })
}

// 获取理赔列表
const getSingleList = (data = {}) => {
    console.log(data);
    return request({
        url: `${baseConfig.skURL}/claim/single/list`,
        method: 'GET',
        params: data
    })
}
// 理赔申请
const saveClaim = (data = {}) => {
    return request({
        url: `${baseConfig.skURL}/claim/save`,
        method: 'POST',
        data
    })
}

// 保单批改
const saveCorrect = (data = {}) => {
    return request({
        url: `${baseConfig.skURL}/policy/H5/employee/insured/review`,
        method: 'PUT',
        data
    })
}

// 下载保单
function downloadCorrect(params = {}) {
    return request({
        url: `${baseConfig.skURL}/policy/download`,
        method: 'GET',
        timeout: 15000,
        responseType: 'blob',
        params,
    });
}

// 产品详情图片
function productImg(params = {}) {
    return request({
        url: `${baseConfig.productImgUrl}/product/image`,
        method: 'GET',
        timeout: 15000,
        // responseType: 'blob',
        params,
    });
}

export {
    getHomeData,
    getPolicyDetailData,
    getPolicyHolderManagement,
    getMyOrders,
    getProductDetail,
    getElectPolicy,
    getProductInsuredAttr,
    getPremium,
    searchKeyword,
    savePolicy,
    undoPolicy,
    upLoadOcr,
    getPayParams,
    getDeclaration,
    getTermsDetail,
    getLogin,
    getUserInfor,
    userBooking,
    getAreaList,
    saveOrderPolicy,
    getSingleList,
    saveClaim,
    getPayInfo,
    checkIfVerify,
    saveCorrect,
    downloadCorrect,
    productImg
}
