import Vue from 'vue'
import VueRouter from 'vue-router'
import ProductList from '../views/ProductList/ProductList.vue'
import Login from '../views/Login/Login.vue';
import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/file',
        name: 'File',
        component: () => import('../File.vue'),
        meta: {
            title: '所有前端页面'
        }
    },
    {
        path: '/',
        redirect: '/ProductList'
    },
    {
        path: '/productList',
        name: 'ProductList',
        component: ProductList,
        meta: {
            title: '首页'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: '登录'
        }
    },
    {
        path: '/productDetail',
        name: 'ProductDetail',
        component: () => import('../views/ProductDetail/ProductDetail.vue'),
        meta: {
            title: '产品详情'
        }

    },
    {
        path: '/policyInfoForm',
        name: 'PolicyInfoForm',
        component: () => import('../views/PolicyInfoForm/PolicyInfoForm.vue'),
        meta: {
            title: '信息填写',
        }
    },
    {
        path: '/confirmInfo',
        name: 'ConfirmInfo',
        component: () => import('../views/ConfirmInfo/ConfirmInfo.vue'),
        meta: {
            title: '信息确认'
        }
    },
    {
        path: '/policyDetails',
        name: 'PolicyDetails',
        component: () => import('../views/PolicyDetails/PolicyDetails.vue'),
        meta: {
            title: '保单详情页',
        }
    },
    {
        path: '/policyList',
        name: 'PolicyList',
        component: () => import('../views/PolicyList/PolicyList.vue'),
        meta: {
            title: '我的保单'
        }

    },
    {
        path: '/policySchemeDetails',
        name: 'PolicySchemeDetails',
        component: () => import('../views/PolicySchemeDetails/PolicySchemeDetails.vue'),
        meta: {
            title: '保障详情'
        }
    },
    {
        path: '/settlement',
        name: 'Settlement',
        component: () => import('../views/Settlement/Settlement.vue'),
        meta: {
            title: '我的理赔'
        }
    },
    {
        path: '/settlementApply',
        name: 'SettlementApply',
        component: () => import('../views/Settlement/SettlementApply/SettlementApply.vue'),
        meta: {
            title: '申请理赔'
        }
    },
    {
        path: '/settlementRecord',
        name: 'SettlementRecord',
        component: () => import('../views/Settlement/SettlementRecord/SettlementRecord.vue'),
        meta: {
            title: '理赔记录'
        }
    },
    {
        path: '/paymentFinish',
        name: 'PaymentFinish',
        component: () => import('../views/PaymentFinish/PaymentFinish.vue'),
        meta: {
            title: '投保成功'
        }
    },
    {
        path: '/sunshineInsBooking',
        name: 'SunshineInsBooking',
        component: () => import('../views/SunshineInsBooking/SunshineInsBooking.vue'),
        meta: {
            title: '阳光保险预约'
        }
    },
    {
        path: '/book/nursing',
        name: 'BookNursing',
        redirect: '/book/nursing/index',
        component: () => import('../views/BookNursing'),
        meta: {
            title: '护理预约'
        },
        children: [
            {
                name: 'index',
                path: 'index',
                component: () => import('../views/BookNursing/Form'),
                meta: {
                    title: '护理预约'
                },
            },
            {
                name: 'result',
                path: 'result',
                component: () => import('../views/BookNursing/Result'),
                meta: {
                    title: '护理预约'
                },
            }
        ]
    },
    {
        path: '/newPdf',
        name: 'newPdf',
        component: () => import('../views/newPdf.vue'),
        meta: {
            title: '查看'
        }
    }
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
};

router.beforeEach((to, from, next) => {
    if (to.query.customerId) {
        localStorage.setItem('attach', JSON.stringify(to.query.customerId))
    }
    if (to.query.channelId) {
        localStorage.setItem('channelId', JSON.stringify(to.query.channelId))
    }

    //小程序分享链接进来时直接跳转到detail
    if (to.hash === '#wechat_redirect') {
        router.push({
            path: './productDetail',
            query: {
                productId: to.query.productId,
                unitId: to.query.unitId,
                openId: to.query.openId,
                sourceType: to.query.sourceType,
                isShowFooter: to.query.isShowFooter,
                planCode: to.query.planCode,
                inviteCode: to.query.inviteCode,
                customerId: to.query.customerId
            }
        })
    }

    if (to.hash === '#redirect') {
        router.push({
            path: './policyInfoForm',
            query: {
                time: (new Date()).getTime()
            }
        })
    }

    //H5授权后返回login页面处理返回来的code值
    if (to.query.state && to.query.state.indexOf('&login') != -1) {
        router.push({
            path: './login',
            query: {
                code: to.query.code,
                state: to.query.state.slice(0, to.query.state.indexOf('&login'))
            }
        })
    }

    if (to.meta.title) {
        document.title = to.meta.title;
        store.dispatch('setWebTitle', to.meta.title);
    }
    store.dispatch('goUrl', to.fullPath);
    next()
})
export default router
