<template>
  <div class="product_item" @click="goDetail">
    <img
      v-if="!item.productPictureUr"
      :src="item.productPictureUrl"
      class="img"
      alt=""
    />
    <div class="product_item_box">
      <div>
        <div class="item_box_title">{{ item.productName }}</div>
        <div class="item_box_detail">
          <div class="item_box_detail_msg">{{ item.recommendReasons }}</div>
        </div>
      </div>
      <div class="btn">去投保</div>
    </div>
    <div class="item_box_detail_price">
      <p>
        <span>{{ item.insurances.insuranceAmount.propertyPremium }}</span>
      </p>
      <p>销售量{{ item.count }}份</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductItem',
  data() {
    return {}
  },
  props: ['item'],
  methods: {
    goDetail() {
      this.$router.push({
        path: './productDetail',
        query: {
          productId: this.item.id,
          planCode: this.item.insurances.insuranceAmount.propertyValue
        }
      })
    }
  }
}
</script>

<style lang="less">
.product_item {
  background-color: #fff;
  height: 2.2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.16rem;
  padding: 0.3rem 0.2rem 0;
  border-radius: 0.08rem;
  position: relative;
  .img {
    height: 2rem;
    width: 1.8rem;
    background-color: #d8d8d8;
    border-radius: 0.08rem;
  }

  .product_item_box {
    margin-left: 0.34rem;
    width: 4.86rem;
    height: 2rem;
    display: flex;

    .item_box_title {
      color: #333;
      font-size: 0.3rem;
      line-height: 0.42rem;
      margin-bottom: 0.2rem;
      font-weight: 700;
    }

    .item_box_detail {
      color: #666;
      font-size: 0.26rem;
      line-height: 0.4rem; //0.36rem;
      height: 0.88rem;

      .item_box_detail_msg {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .item_box_detail_price {
    font-size: 0.3rem;
    height: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    right: 0.2rem;
    width: 4.86rem;
    p {
      font-size: 0.25rem;
    }
    p:nth-of-type(1) {
      color: #ed6b64;
      span {
        font-size: 0.36rem;
      }
    }
  }
  .btn {
    height: 0.7rem;
    width: 2rem;
    border-radius: 0.1rem;
    color: #f0847f;
    font-size: 0.3rem;
    line-height: 0.7rem;
    border: 0.1px solid #f0847f;
    text-align: center;
  }
}
</style>
