<!--
 * @Author: your name
 * @Date: 2020-11-11 21:44:06
 * @LastEditTime: 2020-11-11 22:20:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \insurance-h5\src\views\Login\Login.vue
-->
<template>
  <div></div>
</template>

<script>
import { getLogin } from '../../api/api'
import { Toast } from 'vant'
import { baseConfig } from '../../utils/request'
import wx from 'weixin-js-sdk'
export default {
  name: 'Login',
  data() {
    return {
      query: "",
      newQuery: ""
    };
  },
  mounted() {
    console.log('login', window.location, this.query);
    this.query = this.getQuery(window.location.search.split("?")[1], true);
    this.newQuery = this.getQuery(decodeURIComponent(this.query.state).split("?")[1], true) || {};
	const loginInfo = localStorage.getItem('loginInfo');
	if(!loginInfo){
	   this.getRequest();
	}else{
		wx.miniProgram.getEnv((res) => {
			if(res.miniprogram){
				wx.miniProgram.navigateBack({delta:1});
			}else{
				wx.closeWindow();
			}
			
		})
	}

    console.log('code', this.query.code);
  },
  methods: {
    // 获取参数的方法
    getQuery(queryString, type) {
      if (!queryString) return;
      var str = type ? "&" : "*";
      var str1 = type ? "=" : "-";
      let result = queryString.split(str);
      result = result.reduce(function (memo, item) {
        item = item.split(str1);
        if (item.length == 2) {
          memo[item[0]] = item[1];
        }
        return memo;
      }, {});
      return result;
    },
    async getRequest() {
      Toast.loading({
        message: '登录中',
        forbidClick: true,
        duration: 0,
        loadingType: 'spinner'
      })

      const data = await getLogin({
        code: this.query.code,
        channelId: this.newQuery.channelId,
        customerId: this.newQuery.customerId,
        province: this.newQuery.province,
        city: this.newQuery.city
      })
      Toast.clear()
      console.log('loginInfo', data);
      localStorage.setItem('loginInfo', JSON.stringify({
        openId: data.data.openId,
        unitId: data.data.unionId
      }));
      if (data.success) {
        location.replace(location.origin + decodeURIComponent(this.query.state))
      }
      else {
        Toast.loading({
          message: data.msg
        });
        location.replace(location.origin + decodeURIComponent(this.query.state));
      }
    }
  }
}
</script>

<style>
</style>
