/*
 * @Author: your name
 * @Date: 2020-11-11 21:44:06
 * @LastEditTime: 2022-05-13 10:54:34
 * @LastEditors: wangmengfei
 * @Description: In User Settings Edit
 * @FilePath: \big-tree-insurance-insure\src\utils\request.js
 */
import axios from 'axios'

const baseConfig = {
    // 测试
    // baseURL: 'https://ttapi.dasurebao.com/wechat/dsbjk',
    // appKeyUrl: 'https://ttapi.dasurebao.com/wechat/',
    // locationHref: 'https://ttapi.dasurebao.com/test/h5',

    // 生产
    // baseURL: 'https://yfgb.dasurebao.com.cn/wechat/dsbjk',
    // locationHref: 'https://dsins.bigtreedev.com/',
    // skURL: 'https://dsins.bigtreedev.com/dsinserver-api',
    // testBaseURL: 'https://yfgb.dasurebao.com.cn/wechat/dsbjk',
    // appKeyUrl: 'https://yfgb.dasurebao.com.cn/wechat/',
    // sunlightUrl: 'https://api.bigtreedev.com/openapi/sunlight/createCareOrder',
    // arealist: 'https://api.bigtreedev.com/openplatform/api/area/list/sunlight',

    // 测试
    baseURL: 'https://test.yfgb.dasurebao.com.cn/wechat/dsbjk',
    locationHref: 'https://test.dsins.bigtreedev.com/',
    skURL: 'https://test.dsins.bigtreedev.com/dsinserver-api',
    arealist: 'https://test.dsins.bigtreedev.com/api/openplatform/api/area/list/sunlight',
    testBaseURL: 'https://test.yfgb.dasurebao.com.cn/wechat/dsbjk',
    appKeyUrl: 'https://test.yfgb.dasurebao.com.cn/wechat/',
    // sunlightUrl: 'http://1.14.161.190:8000/openapi/sunlight/createCareOrder',注释
    sunlightUrl: 'https://test.api.bigtreedev.com/openapi/sunlight/createCareOrder',
    baseBigTreeUrl: 'http://1.14.161.190:8000',
    productImgUrl: 'https://test.api.bigtreedev.com/openapi/sunlight',
    appid: 'wxb4388396d81357fa'
}
const instance = axios.create({
    timeout: 100000, // 超时时间
    baseURL: `${baseConfig.baseURL}`,
    appKeyUrl: `${baseConfig.appKeyUrl}`
})


// 文档中的统一设置post请求头。下面会说到post请求的几种'Content-Type'
instance.defaults.headers.post['Content-Type'] = 'application/json'

/** 添加请求拦截器 **/
instance.interceptors.request.use(config => {
    // if (config.url === '/insurance/newSaveAuthPolicy') {
    //     config.baseURL = baseConfig.testBaseURL;
    // }

    if (config.changeAppkey) {
        config.baseURL = `${config.appKeyUrl}${config.appKey}`
    }

    // 因为返回的是二进制流，设置请求响应类型为blob，就可以在此处设置。
    if (config.url.includes('pur/contract/export')) {
        config.headers['responseType'] = 'blob'
    }
    // 文件上传，发送的是二进制流，设置请求头的'Content-Type'
    if (config.url.includes('/insurance/newOcr')) {
        config.headers['Content-Type'] = 'multipart/form-data'
    }
    return config
}, error => {
    // 对请求错误做些什么
    return Promise.reject(error)
})

/** 添加响应拦截器  **/
instance.interceptors.response.use(response => {
    if (response.data.errorCode == '901') {
        const url = `${baseConfig.locationHref}`;
        const oldPath = encodeURIComponent(window.location.href.split(location.origin)[1] + '&login');
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${baseConfig.appid}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=${oldPath}`
    }
    if (response.status === 200) { // 响应结果
        // if (response.data.success) {
        if (response.data.resData) {
            return Promise.resolve(response.data)
        }
        // if (!Object.keys(response.data.body).length) {
        return Promise.resolve(response.data)
        // }
        // return Promise.resolve(response.data.body)
        // } else {
        //     return Promise.reject(response.data.msg)
        // }

    } else {
        return Promise.reject(response.data)
    }
}, error => {
    if (error.response.data.code == '401') {
        const url = `${baseConfig.locationHref}`;
        const oldPath = encodeURIComponent(window.location.href.split(location.origin)[1] + '&login');
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${baseConfig.appid}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=${oldPath}`
    }
    if (error.response) {
        return Promise.reject(error)
    } else {
        return Promise.reject(new Error('请求超时, 请刷新重试'))
    }
})

const request = (options, config = {}) => {
    return new Promise((resolve, reject) => {
        instance({
            ...options,
            ...config
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

export {
    request,
    baseConfig
}