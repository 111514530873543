import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { setRem } from '@/libs/js/setRem'
import './libs/styles/reset.less'
import './assets/fonts/icon-font.less'
Vue.config.productionTip = false

// store
import store from '@/store/index';

setRem(750, 100)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

